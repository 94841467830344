import axios, { Axios } from 'axios'
import store from './store/index'

axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
  if (store.state.user.auth && store.state.user.auth !== 'false') axios.defaults.headers.common.Authorization = `Bearer ${store.state.user.auth}`
  return config
})

axios.interceptors.response.use(response => {
  store.dispatch('feedback/clear')

  if (response.data.errors && response.data.errors.length > 0) {
    throw new Error(response.data.errors)
  }
  if (response.data?.result?.user_feedback?.length > 0) {
    store.dispatch('feedback/add', response.data.result.user_feedback)
  }
  return response
})

class Api extends Axios {
  get (query, params, config = {}) {
    if (config.loading) {
      store.commit('increaseLoading')
    }
    return axios.get(`?q=${query}`, {
      params
    }).then(value => {
      return value
    }).catch(err => {
      if (err.response.data.errors && err.response.data.errors.length > 0) {
        err.message = err.response.data.errors[0]
      }
      throw err
    }).finally(() => {
      if (config.loading) {
        store.commit('decreaseLoading', false)
      }
    })
  }

  post (query, data, config = {}) {
    if (config.loading) {
      store.commit('increaseLoading', true)
    }
    return axios.post(`?q=${query}`, data, config).then(value => {
      if (value.data.errors && value.data.errors.length > 0) {
        throw new Error(value.data.errors[0])
      }
      return value
    }).catch(err => {
      if (err.response && err.response.data.errors && err.response.data.errors.length > 0) {
        err.message = err.response.data.errors[0]
      }
      throw err
    }).finally(() => {
      if (config.loading) {
        store.commit('decreaseLoading', false)
      }
    })
  }

  getUrl () {
    return axios.defaults.baseURL
  }
}

const api = new Api()

export default api
