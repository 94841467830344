<template>
  <FormGroup :title="label" :titleSuffix="labelSuffix" :help="help" :required="required">
    <Grid class="address" :columns="{ tablet: 4 }">
      <Column>
        <Country v-model="localValue.country" :v="AddressError.length ? v && v.country : false && v.country" :id="`${id}-country`" :required="required" :suggestion="suggestion && localValue.country !== suggestion.country && suggestion.country" @input="fetchData" :class="v && v.country && v.country.$error ? 'has-error' : ''" />
      </Column>
      <Column>
        <Select v-model="localValue.postal" :v="AddressError.length ? v && v.postal : false && v.postal" :label="$t('Postcode')" :options="postals" :required="required" :suggestion="suggestion && localValue.postal !== suggestion.postal && suggestion.postal" @input="setCityByPostal" :id="`${id}-postal`" :class="v && v.postal && v.postal.$error ? 'has-error' : ''"  />
      </Column>
      <Column :span="2">
        <Select simple :v="AddressError.length ? v && v.city : false" v-model="localValue.city" :label="$t('Stad')" :options="cities" :required="required" :suggestion="suggestion && localValue.city !== suggestion.city && suggestion.city" :id="`${id}-city`" :class="v && v.city && v.city.$error ? 'has-error' : ''" />
      </Column>
      <Column :span="2">
        <Input :value="localValue.street" :v="AddressError.length ? v && v.street : false" :label="$t('Straat')" :id="`${id}-street`" :required="required" :suggestion="suggestion && localValue.street !== suggestion.street && suggestion.street" @change="value => localValue.street = value" :class="v && v.street && v.street.$error ? 'has-error' : ''" />
      </Column>
      <Column>
        <Input :value="localValue.streetNumber" :v="AddressError.length ? v && v.streetNumber : false" :label="$t('Nummer')" :id="`${id}-streetNumber`" :required="required" :suggestion="suggestion && localValue.streetNumber !== suggestion.streetNumber && suggestion.streetNumber" @change="value => localValue.streetNumber = value" :class="v && v.streetNumber && v.streetNumber.$error ? 'has-error' : ''" />
      </Column>
      <Column>
        <Input v-model="localValue.bus" :v="v && v.bus" :label="$t('Bus')" :id="`${id}-bus`" />
      </Column>
    </Grid>
    <FormError v-if="v && v.$error && localValue.street && localValue.streetNumber && localValue.postal && localValue.city" :v="v" @override="forceValid" />
  </FormGroup>
</template>

<script>
import Input from './Input'
import Select from './Select'
import Grid from './Grid'
import Column from './Column'
import FormGroup from './FormGroup'
import FormError from './FormError'
import Country from './Country'
import api from '../../api'
export default {
  name: 'Address',
  components: {
    Country,
    FormError,
    FormGroup,
    Column,
    Grid,
    Select,
    Input
  },
  props: {
    value: [Object, String],
    label: String,
    labelSuffix: String,
    required: [Boolean, Number],
    v: Object,
    help: String,
    id: String
  },
  mounted () {
    if (this.value) {
      this.localValue = {
        ...this.value,
        isValidAddress: false
      }
    }
    if (this.localValue) {
      this.fetchData()
      this.isValidAddress()
    }
  },
  data () {
    return {
      postalCityFilter: false,
      localValue: {
        street: '',
        streetNumber: '',
        bus: '',
        city: '',
        postal: '',
        country: 'BE',
        isValidAddress: false
      },
      suggestion: false,
      postalCodes: [],
      checkAddress: true
    }
  },
  computed: {
    postals () {
      const postals = this.postalCodes.filter(record => {
        if (this.postalCityFilter === 'city') {
          return record.city === this.localValue.city
        }
        return true
      }).map(record => {
        return record.postal
      })
      return Array.from(new Set(postals))
    },
    cities () {
      const cities = this.postalCodes.filter(record => {
        if (this.postalCityFilter === 'postal' && this.localValue.postal) {
          return record.postal === this.localValue.postal
        }
        return true
      }).map(record => {
        return record.city
      }).sort()
      return Array.from(new Set(cities))
    },
    AddressError () {
      return this.$store.getters['notice/notices']
    }
  },
  methods: {
    refresh () {
      this.$nextTick(() => {
        this.checkAddress = false
        this.localValue = this.value
        this.checkAddress = true
      })
    },
    async fetchData () {
      const response = await api.get('get_addresses', {
        country: this.localValue.country
      })
      this.postalCodes = response.data.result
    },
    setCityByPostal () {
      if (this.postal?.length === 0) {
        this.postalCityFilter = false
      } else {
        this.postalCityFilter = 'postal'
        if (this.cities.length === 1) {
          this.value.city = this.cities[0]
        }
      }
    },
    forceValid () {
      this.localValue.isValidAddress = true
      this.$emit('input', this.localValue)
    },
    async isValidAddress () {
      if (!this.checkAddress) return true
      if ((!this.localValue.country || !this.localValue.city || !this.localValue.street || !this.localValue.streetNumber)) {
        if (this.required) {
          this.localValue.isValidAddress = false
        }
        return false
      }
      api.get('validate_address', {
        ...this.localValue
      }).then(response => {
        this.localValue.isValidAddress = response.data.result === 1
        this.suggestion = response.data.suggestion
        if (this.suggestion && this.suggestion.street !== this.localValue.street) {
          if (this.suggestion.street.toLowerCase() === this.localValue.street.toLowerCase()) {
            this.localValue.street = this.suggestion.street
          } else {
            this.localValue.isValidAddress = false
          }
        }
        if (this.suggestion && this.suggestion.streetNumber !== this.localValue.streetNumber) {
          this.localValue.isValidAddress = false
        }
        if (this.localValue.isValidAddress) this.$emit('validated')
        if (this.v) this.v.$touch()
      })
    }
  },
  watch: {
    async 'localValue.city' () {
      await this.isValidAddress()
      this.$emit('input', this.localValue)
    },
    async 'localValue.postal' () {
      await this.isValidAddress()
      this.$emit('input', this.localValue)
    },
    async 'localValue.street' () {
      await this.isValidAddress()
      this.$emit('input', this.localValue)
    },
    async 'localValue.bus' () {
      this.$emit('input', this.localValue)
    },
    async 'localValue.streetNumber' () {
      await this.isValidAddress()
      this.$emit('input', this.localValue)
    }
  }
}
</script>
