import moment from 'moment'
import { helpers, email as emailValidator, not } from 'vuelidate/lib/validators'
import emailSpellChecker from '@zootools/email-spell-checker'

export const moreThanYearsAgo = (years, format = 'YYYY-MM-DD') => helpers.withParams(
  { value: years },
  (value) => {
    const date = !isNaN(value) ? moment(value) : moment(value, format)
    const yearsAgo = moment().diff(date, 'years')
    return yearsAgo >= years
  }
)

export const isArrayOfEmails = (emails) => {
  if (!emails) return true
  let areAllEmails = true
  if (!Array.isArray(emails)) {
    emails = emails.split(';')
  }
  for (const email of emails) {
    if (!emailValidator(email)) {
      areAllEmails = false
    }
  }
  return areAllEmails
}

export const isValidEmail = (email) => {
  const suggestion = emailSpellChecker.run({
    email: email,
    domains: [...emailSpellChecker.POPULAR_DOMAINS.filter((domain) => !['cox.net', 'cox.be', 'cox.com'].includes(domain)), 'skynet.be', 'telenet.be', 'gmail.be', 'gmail.de', 'gmail.nl', 'yahoo.co.id', 'abv.bg', 'web.com', 't-live.be'],
    topLevelDomains: emailSpellChecker.POPULAR_TLDS.filter((tlds) => !['il', 'co.il', 'ro'].includes(tlds))
  })

  return suggestion ? 0 : 1
}

export const isPhone = (phone) => {
  if (!phone) return true
  if (!phone.includes('+')) return false
  // Only validate BE, FR, NL, DE
  const countrySign = phone.substr(0, 3)
  if (!['+32'].includes(countrySign)) return true
  let phoneNumber = phone.substr(4).split(' ').join('').trim()
  if (phone[4] === '0') phoneNumber = phoneNumber.replace('0', '')
  if (phoneNumber.length < 9 || phoneNumber.length > 9) return false
  return true
}

export const isBirthdate = (value) => {
  if (!value) return true
  let format = 'DD/MM/YYYY'
  if (typeof value === 'object') {
    format = value.format
    value = value.value
  }
  const date = moment(value, format)
  if (!date.isValid()) return false
  if (date.isBefore(moment().subtract(100, 'years'))) return false
  if (date.isAfter(moment().subtract(1, 'days'))) return false
  return true
}

export const nameValidator = not(helpers.regex('nameValidator', /\d/))

export const isNationalInsuranceNumber = (nationalInsuranceNumber, birthdate) => helpers.withParams({ birthdate }, () => {
  if (!nationalInsuranceNumber) return false

  const trimmedNationalInsuranceNumber = nationalInsuranceNumber.split(' ').join('').split('.').join('').split('-').join('').trim()
  if (trimmedNationalInsuranceNumber.length !== 11 || isNaN(trimmedNationalInsuranceNumber)) return false

  return true
})

export const isNationalInsuranceNumberValidWithBirthdate = (nationalInsuranceNumber, birthdate) => helpers.withParams({ birthdate }, () => {
  if (!nationalInsuranceNumber) return false

  const trimmedNationalInsuranceNumber = nationalInsuranceNumber.split(' ').join('').split('.').join('').split('-').join('').trim()

  const ninBirthdateDigits = trimmedNationalInsuranceNumber.slice(0, 6)
  let birthdateDigits = false

  if (isNaN(birthdate)) {
    birthdateDigits = moment(birthdate, 'DD/MM/YYYY').format('YYMMDD')
  } else {
    birthdateDigits = moment.unix(birthdate).format('YYMMDD')
  }

  return ninBirthdateDigits === birthdateDigits
})
